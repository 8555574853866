import * as React from "react";
import { PageLayout, SEO } from "@components/page-layout";
import { Box } from "@components/box";
import { GrayPhrase, Paragraph } from "@components/typography";
import { KeyTerms } from "@containers/key-terms";

const KeyTermsPage = () => (
  <PageLayout>
    <SEO title="Key terms" />
    <Box className="px-[22px]">
      <Box className="text-center mt-[120px] sm:mt-[44px] lg:mt-[100px] sm:text-left">
        <h1 className="global--page-heading">Loan & financial key terms</h1>
      </Box>
      <Paragraph className="max-w-[660px] mt-[22px] mb-[5.5rem] sm:mb-[22px] mx-auto text-center sm:text-left">
        <GrayPhrase>
          The process of borrowing cash and sifting through agreements and
          financial jargon can be stressful. We want to make the process of
          getting a loan as easy and straightforward as possible. You won't find
          any small print or confusing acronyms here! Just clear and simple key
          financial terms, with easy-to-understand definitions.
        </GrayPhrase>
      </Paragraph>
    </Box>
    <KeyTerms />
  </PageLayout>
);

export default KeyTermsPage;
