import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Box } from "@components/box";
import type { KeyTermLetterType, KeyTermNodeType } from "./types";
import { KeyTermLetter } from "@brainfinance/icash-component-library";

import cross from "@images/ic-x.svg";
import icon from "@images/icash-symbol-white.svg";

const findTermsByLetter = (nodes: KeyTermNodeType[], letter: string) => nodes.find((node) => node.letter === letter);

export const KeyTerms = () => {
  const {
    allContentfulKeyTerms: { nodes },
  } = useStaticQuery(graphql`
    query allKeyTermsQuery {
      allContentfulKeyTerms {
        nodes {
          letter
          terms {
            term
            description
          }
        }
      }
    }
  `);

  const [activeLetter, setActiveLetter] = React.useState<string>("B");
  const [activeElement, setActiveElement] = React.useState<number>(0);
  const [boxIsOpen, setBoxIsOpen] = React.useState<boolean>(false);

  const handleSelectLetter = (letter: string) => {
    setActiveElement(0);
    setActiveLetter(letter);
  };

  const handleSelectElement = (key: number) => {
    setActiveElement(key);
    setBoxIsOpen(true);
  };

  const handleClose = () => setBoxIsOpen(false);

  const selectedLetterTerms = React.useMemo(
    () => findTermsByLetter(nodes, activeLetter)?.terms,
    [activeLetter, activeLetter]
  );

  return (
    <Box className="px-[2.75rem] sm:px-[22px]">
      <Box className="text-center mb-[5.5rem] mx-auto md:max-w-[810px] md:mb-[2.75rem] sm:mb-[22px] sm:text-left sm:mx-auto">
        {nodes
          .sort((a: KeyTermNodeType, b: KeyTermNodeType) => a.letter.localeCompare(b.letter))
          .map((keyTerm: KeyTermNodeType) => (
            <KeyTermLetter
              key={keyTerm.letter}
              active={keyTerm.letter === activeLetter}
              letter={keyTerm.letter as KeyTermLetterType}
              onClick={handleSelectLetter}
            />
          ))}
      </Box>
      {selectedLetterTerms && (
        <div className="segmented-content--container">
          <ul className="segmented-content--list">
            {selectedLetterTerms.map(({ term }, key) => (
              <li
                key={key}
                className={`segmented-content--item${key === activeElement ? " active" : ""}`}
                onClick={() => handleSelectElement(key)}
              >
                {term}
              </li>
            ))}
          </ul>
          {activeElement !== null && (
            <div className={`segmented-content--box${boxIsOpen ? " open" : ""}`}>
              <div className="segmented-content--close-box">
                <button className="text-interface-50" onClick={handleClose}>
                  <img src={cross} alt="Close" />
                </button>
              </div>
              <img className="segmented-content--icon" src={icon} alt="Logo" />
              <h3 className="global--subheading">{selectedLetterTerms[activeElement].term}</h3>
              <div className="mt-[2.75rem] text-interface-50 whitespace-pre-wrap">
                <p className="global--paragraph">{selectedLetterTerms[activeElement].description}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};
